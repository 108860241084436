@import "../media-queries";

.description {
    &__image {
        width: 75%;
        height: calc(100vh - 176px);
        z-index: 2;

        @include phone {
            width: 100%;
        }
    }

    &__header {
        &__container {
            position: absolute;
            top: 0;
            left: 25%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            transform: translateX(-50%);

            @include phone {
                top: 0;
                left: 0;
                align-items: center;
                width: 100%;
                height: 100%;
                transform: translateX(0);
            }
        }

        span {
            font-size: 80px;

            @include phone {
                font-size: 48px !important;
            }
        }
    }
}
