$tablet-width: 768px;
$desktop-width: 1028px;

@mixin phone {
    @media only screen and (max-width: #{$tablet-width - 1px}) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}
