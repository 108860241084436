@import "../colors";
@import "../media-queries";

.video {
    position: relative;
    width: auto;
    height: 100%;
    overflow: hidden;

    &__embed {
        position: relative;
        width: 200%;
        height: auto;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        z-index: 2;
    }

    /* Has default z-index: 3 */
    &__filter {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: 1;
        transition: 0.25s all ease-out;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;

        &--reveal {
            opacity: 0.5;
        }
    }
}

.video-background {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden !important;

    @include phone {
        width: 100vw;
        height: 100vh;
    }

    &__embed {
        position: fixed;
        width: 100%;
        height: auto;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        z-index: 2;

        @include phone {
            width: auto;
            height: 100vh;
        }
    }

    &__filter {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: $black;
        opacity: 1;
        z-index: 5;
        transition: 0.25s all ease-out;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;

        &--reveal {
            opacity: 0.5;
        }
    }
}
