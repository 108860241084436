@import "../colors";
@import "../media-queries";

.computer {
    width: 100%;
    border: 1px solid $orange;
    border-radius: 4px;

    &__menu-bar {
        height: 32px;
        border-bottom: 1px solid $orange;
    }

    &__content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 512px;
        overflow: hidden;

        @include phone {
            flex-direction: column;
        }

        & > *:first-child {
            margin-right: 14px;
        }

        &__fill {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $orange;
            transition: all 2s ease-out;

            &--visible {
                top: 0;
            }
        }
    }

    &__menu-bar {
        display: flex;
        align-items: center;
        padding-left: 16px;

        &__circle {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            border: 1px solid $orange;
            border-radius: 50%;
        }
    }
}
