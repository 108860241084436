@import "../colors";
@import "../media-queries";

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 128px;
}

.input {
    width: 100%;
    border: none;
    border-bottom: 1px solid $white;
    border-radius: 0;
    padding: 8px 0;
    background-color: transparent;
    color: $white;
    font-size: 32px;
    transition: border 0.25s ease-out;

    &:focus {
        outline: none;
        border-bottom: 1px solid $orange;
    }

    &:not(:last-child) {
        margin-bottom: 48px;
    }

    @include phone {
        font-size: 20px;
    }
}
