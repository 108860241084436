@import "../colors";
@import "../media-queries";

.music-first {
    &__header {
        transition: all 1s ease-out;

        &--visible {
            left: 0 !important;
            opacity: 1 !important;
        }

        &--one,
        &--three,
        &--five {
            left: -100px;
            opacity: 0;
        }

        &--two,
        &--four {
            left: 100px;
            opacity: 0;
        }

        &--two {
            transition-delay: 0.25s;
        }

        &--three {
            transition-delay: 0.5s;
        }

        &--four {
            transition-delay: 0.75s;
        }

        &--five {
            transition-delay: 1s;
        }
    }
}
