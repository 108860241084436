@import "../colors";
@import "../layouts";
@import "../media-queries";

.section {
    width: 100%;
    padding: 88px;
    position: relative;
    background-color: white;

    @include phone {
        padding: 32px;
    }

    &--background {
        &-black {
            background-color: $black;
        }

        &-gray {
            background-color: $gray;

            &-light {
                background-color: $gray-light;
            }
        }

        &-orange {
            background-color: $orange;

            &-burnt {
                background-color: $orange-burnt;
            }
        }
    }

    &--full-screen {
        min-height: 100vh;
    }

    &--layout {
        &-flex-center {
            @include flex-center;
        }

        &-flex-column-center {
            @include flex-column-center;
        }

        &-flex-end {
            @include flex-end;
        }

        &-flex-row-center {
            @include flex-row-center;
        }

        &-grid-1-1 {
            @include grid-1-1;
        }

        &-grid-2-8 {
            @include grid-2-8;
        }

        &-grid-3-7 {
            @include grid-3-7;
        }
    }

    &--padding {
        &-none {
            padding: 0;
        }

        &-x-medium {
            padding: 88px 10%;

            @include phone {
                padding: 32px;
            }
        }

        &-x-none {
            padding: 88px 0;

            @include phone {
                padding: 32px 0;
            }
        }

        &-x-tight {
            padding: 88px 25%;

            @include phone {
                padding: 32px;
            }
        }
    }
}
