@import "../colors";
@import "../layouts";
@import "../media-queries";

.list {
    margin: 0;
    font-size: 24px;
    font-weight: 700;

    @include phone {
        font-size: 24px;
    }

    &--align {
        &-center {
            text-align: center;
        }
    }

    &--style-type {
        &-none {
            padding-left: 0;
            list-style-type: none;
        }
    }

    li {
        cursor: pointer !important;
        transition: color 0.25s ease-out;

        &:hover {
            color: $orange;
        }
    }

    li:not(:last-child) {
        margin-bottom: 32px;
    }
}
