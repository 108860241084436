@import "../media-queries";

.contact {
    &__footer {
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 2px;

        @include phone {
            font-size: 12px;
        }
    }

    &__header {
        font-size: 72px;
        margin-bottom: 48px;

        @include phone {
            font-size: 56px;
        }
    }
}
