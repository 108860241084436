@import "../colors";
@import "../media-queries";

.text {
    position: relative;
    line-height: 1.5;
    font-size: 28px;
    font-weight: 500;

    @include phone {
        font-size: 24px;
    }

    &--align {
        &-center {
            text-align: center;
        }
    }

    &--color {
        &-gray {
            color: $gray;
        }

        &-orange {
            color: $orange;
        }
    }

    &--size {
        &-40 {
            font-size: 40px;
        }
    }

    &--weight {
        &-400 {
            font-weight: 400;
        }
    }
}
