.gif {
    width: 100%;
    height: auto;
}

.image {
    position: relative;
    width: 100%;
    background-position: center;
    background-size: cover;
    transition: all 1.5s ease-out;

    &--rounded-corners {
        border-radius: 8px;
    }

    &__texture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000000;
    }
}

.scroll-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 64px 96px;
    overflow: hidden;

    &::-webkit-scrollbar {
        width: 0;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #ff0000;
    }

    & > * {
        width: 100%;
        height: auto;
        object-fit: contain;

        &:not(:last-child) {
            margin-bottom: 128px;
        }
    }
}

.slideshow {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & > * {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        animation-duration: 21s;
        animation-iteration-count: infinite;
        animation-name: fade;
        transform: translate(-50%, -50%);
        opacity: 0;

        &:nth-child(7) {
            animation-delay: 18s;
        }
        &:nth-child(6) {
            animation-delay: 15s;
        }
        &:nth-child(5) {
            animation-delay: 12s;
        }
        &:nth-child(4) {
            animation-delay: 9s;
        }
        &:nth-child(3) {
            animation-delay: 6s;
        }
        &:nth-child(2) {
            animation-delay: 3s;
        }
        &:nth-child(1) {
            animation-delay: 0;
        }
    }
}

/* 4.76% */
@keyframes fade {
    0% {
        opacity: 0;
    }
    4.76% {
        opacity: 1;
    }
    14.28% {
        opacity: 1;
    }
    19.04% {
        opacity: 0;
    }
    44% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
