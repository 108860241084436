@import "./media-queries";

@mixin flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@mixin flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

@mixin flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

@mixin flex-row-center {
    display: flex;
    justify-content: center;

    @include phone {
        flex-direction: column;
    }
}

@mixin flex-start {
    display: flex;
    flex-direction: row;
}

@mixin grid-1-1 {
    display: grid;
    grid-template-columns: 50% 50%;

    @include phone {
        display: flex;
        flex-direction: column;
    }
}

@mixin grid-2-8 {
    display: grid;
    grid-template-columns: 20% 80%;
}

@mixin grid-3-7 {
    display: grid;
    grid-template-columns: 30% 70%;
}
