@import "../colors";
@import "../media-queries";

.header {
    position: relative;
    font-weight: 800;
    font-size: 64px;
    text-align: center;

    @include phone {
        font-size: 48px;
    }

    & + .header {
        margin-top: 16px;
    }

    &--color {
        &-orange {
            color: $orange;
            -moz-text-stroke-color: $orange;
            -webkit-text-stroke-color: $orange;
            -moz-text-stroke-width: 2px;
            -webkit-text-stroke-width: 2px;
        }

        &-white {
            color: $white;
            -moz-text-stroke-color: $white;
            -webkit-text-stroke-color: $white;
            -moz-text-stroke-width: 2px;
            -webkit-text-stroke-width: 2px;
        }
    }

    &--margin {
        &-none {
            margin: 0 !important;
        }
    }

    &--size {
        &-80 {
            font-size: 80px;

            @include phone {
                font-size: 56px;
            }
        }
    }

    &--transparent {
        -moz-text-fill-color: transparent;
        -webkit-text-fill-color: transparent;
    }

    &--vertical {
        writing-mode: vertical-lr;

        @include phone {
            writing-mode: horizontal-tb;
        }
    }

    span {
        z-index: 1000 !important;
    }
}
