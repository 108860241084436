@import "../colors";
@import "../layouts";
@import "../media-queries";

.square {
    position: relative;
    width: 100%;
    padding: 32px;

    &--border {
        &-orange {
            border: 32px solid $orange;

            @include phone {
                border: 16px solid $orange;
            }
        }
    }

    &--layout {
        &-flex-column-center {
            @include flex-column-center;
        }
    }

    & > *:not(:last-child) {
        margin-bottom: 56px;

        @include phone {
            margin-bottom: 40px;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-bottom: 0 !important;
        padding: 120px;
        font-weight: 600;
        font-size: 24px;
        text-align: center;
        opacity: 0;
        overflow: scroll;
        visibility: hidden;
        transition: opacity 0.25s ease-out;
        z-index: 1000;

        @include phone {
            position: fixed;
            padding: 16px;
        }

        &--background {
            &-orange {
                background-color: $orange-burnt;

                &-light {
                    background-color: $orange-light;
                }
            }
        }

        &--color {
            &-black {
                color: $black;
            }

            &-white {
                color: $white;
            }
        }

        &--visible {
            opacity: 1;
            visibility: visible;
        }

        &__close {
            position: absolute;
            top: 32px;
            left: calc(100% - 56px);
            font-weight: 900;
            cursor: pointer;
            z-index: 100;

            &--color {
                &-black {
                    color: $black;
                }

                &-white {
                    color: $white;
                }
            }
        }

        &__text {
            &__body {
                p {
                    color: $gray-light;
                    font-weight: 400;
                }
            }

            &__header {
                width: 100%;
                margin-bottom: 24px;
                color: $gray-light;
                font-size: 32px;
                font-weight: 700;
                text-align: center;
            }
        }
    }
}
