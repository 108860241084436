.blurb {
    &__text {
        position: relative;
        left: -128px;
        opacity: 0;
        transition: all 1s ease-out;

        &--visible {
            left: 0;
            opacity: 1;
        }
    }
}
