@import "../colors";

.button {
    border: none;
    padding: 16px;
    background-color: $white;
    color: $black;
    font-size: 24px;
    transition: all 0.5s ease-out;
    opacity: 1;

    &:hover {
        cursor: pointer;
    }

    &--active {
        opacity: 0.5;
    }
}
