@import "../colors";

.marquee {
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

    &__block {
        display: inline-block;
        margin-right: 8px;

        &--first {
            margin-left: 8px;
            animation: marquee 20s linear infinite;
        }

        &--second {
            animation: marquee2 20s linear infinite;
            animation-delay: 10s;
        }
    }
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@keyframes marquee2 {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-200%);
    }
}
