@import "../colors";

.lets-talk {
    cursor: pointer;

    &__header {
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-name: flash-text;
        animation-timing-function: linear;

        &:nth-of-type(1) {
            animation-delay: 0;
        }

        &:nth-of-type(2) {
            animation-delay: 1s;
        }

        &:nth-of-type(3) {
            animation-delay: 2s;
        }
    }
}

@keyframes flash-text {
    0% {
        -moz-text-fill-color: $white;
        -webkit-text-fill-color: $white;
    }
    33.32% {
        -moz-text-fill-color: $white;
        -webkit-text-fill-color: $white;
    }
    33.33% {
        -moz-text-fill-color: transparent;
        -webkit-text-fill-color: transparent;
    }
    100% {
        -moz-text-fill-color: transparent;
        -webkit-text-fill-color: transparent;
    }
}
