@import "../colors";
@import "../media-queries";

.what-we-do {
    &-header-list {
        font-size: 32px !important;

        @include phone {
            flex-direction: row !important;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 16px;
            margin-right: 0 !important;
        }

        & > * {
            @include phone {
                margin-top: 0 !important;
            }

            &:first-child,
            &:nth-child(3) {
                @include phone {
                    margin-right: 16px;
                }
            }
        }
    }

    &__square {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__overlay {
            &__link {
                border-bottom: 1px solid $gray-light;
                color: $gray-light;
            }
        }
    }
}
