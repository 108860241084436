@import "./media-queries";

* {
    box-sizing: border-box;
    font-family: "Raleway", helvetica, sans-serif;
    z-index: 3;
}

html {
    scroll-behavior: smooth;

    @include phone {
        scroll-behavior: auto;
    }
}

.app {
    width: 100vw;
}

.no-scroll {
    position: fixed !important;
}
